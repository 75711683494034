import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import { Heading, P } from "../../../packages/Typography/src";
import Modal from "../../../packages/Modal/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Button from "../../../packages/Button/src";

var ModalWithControlsPattern = function ModalWithControlsPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Flex, {
    justifyContent: "center",
    mt: "5rem"
  }, React.createElement(Modal, {
    disclosure: React.createElement(Button, {
      variant: "primary"
    }, "Open modal")
  }, function (dialog) {
    return React.createElement(Div, null, React.createElement(Heading, {
      as: "h3",
      size: "xl",
      my: "0"
    }, "Headline goes here"), React.createElement(P, {
      size: "sm",
      mt: "0.75rem"
    }, "The Modal is a dialog box/popup window which can be used for lightboxes, user notifications, UI enhancements, e-commerce components. The user has to take action before continuing their activity."), React.createElement(Div, {
      align: "right"
    }, React.createElement(Button, {
      mr: "0.75rem",
      onClick: function onClick() {
        return dialog.hide();
      }
    }, "Cancel"), React.createElement(Button, {
      variant: "primary"
    }, "Continue")));
  })));
};

export default ModalWithControlsPattern;